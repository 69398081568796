<template>
  <div class="content">


    <div class="slide1">
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide :key="index" class="swiperSlide" v-for="(item,index) in swiperList">
          <a :href="item.link" target="_blank"><img :src="$url+item.image" alt=""></a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <div class="view">
      <div style="width:100%;margin-top:80px;margin-bottom: 80px;"><img class="team1" src="../assets/images/link.png">
      </div>


      <div style="width:100%;"><img class="team"  :src="$url+info.image"></div>

      <div style="width:100%;margin-top:80px;margin-bottom:80px;line-height:30px;text-align: center"><p>添加客服了解活动
      </p>
        <p>最新动态
        </p>
      </div>
      <div style="margin-bottom:40px;width:100%;">
        <el-row style="text-align: center; margin-top:80px;width:85%;margin:0 auto;">
          <el-col :span="8" style="line-height: 35px;"><p style="line-height: 35px;font-size:18px;">我们的地址</p>
            <p style="line-height: 35px;font-size:14px;">{{info.address}}</p></el-col>
          <el-col :span="8" style="border-left: 1px solid #999;border-right: 1px solid #999;"><p
              style="line-height: 35px;font-size:18px;">联系电话</p>
            <p style="line-height: 25px;font-size:14px;">联系人:{{info.contacts}}</p>
            <p style="line-height: 25px;font-size:14px;">联系热线:{{info.phone}}</p></el-col>
          <el-col :span="8"><p style="line-height: 35px;font-size:18px;">邮箱地址</p>
            <p style="line-height: 35px;font-size:14px;">{{info.email}}</p></el-col>
        </el-row>
      </div>

      <!--      <h1>联系我们</h1>-->
      <!--      &lt;!&ndash;      <loading :empty="empty" :loading="loading"></loading>&ndash;&gt;-->
      <!--      <div class="list">-->
      <!--        <div  class="item" >-->
      <!--          <baidu-map style="height:400px;" :center="center" :zoom="zoom" @ready="handler">-->

      <!--            <bm-marker  :position="{lng: center.lng, lat: center.lat}"-->
      <!--                       @click="lookDetail(item)">-->
      <!--            </bm-marker>-->
      <!--          </baidu-map>-->
      <!--        </div>-->
      <!--        <div  class="item1" >-->
      <!--          <p>国际中文朗诵金梅花奖</p>-->
      <!--          <p>联系热线：15701681869</p>-->
      <!--          <p>联系人：刘老师</p>-->
      <!--          <p>地址：北京市大兴区波普中心1号楼2802</p>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import moment from "moment";


export default {
  name: "academicExchange",
  data() {
    return {
      list: [],
      nowIndexs: 1,
      sumPage: 1,
      empty: false,
      center: {lng: 116.337766, lat: 39.755502},
      zoom: 7,
      info:{
        image:'',
        address:'',
        phone:'',
        email:'',
        contacts:'',
      },
      swiperList: [],
      swiperOption: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 5000,
        },

      },

    }
  },
  methods: {
    getBanner() {
      this.$get('index/banner')
          .then(res => {
            this.swiperList = res.data
          })
    },
    lianxi(){
      this.$get('index/lianxi')
          .then(res => {
            this.info.image = res.data[0]['value']
            this.info.address = res.data[1]['value']
            this.info.phone = res.data[2]['value']
            this.info.email = res.data[3]['value']
            this.info.contacts = res.data[4]['value']
          })
},
    fetchData() {
      this.$get('index/news', {params: {type: 3, page: this.nowIndexs}})
          .then(res => {
            let arr = this.list
            if (res.data) {
              res.data.data.map(c => {
                arr.push(c)
              })
              this.sumPage = res.data.last_page
            } else {
              this.empty = true
            }

          })
    },
    handler({BMap, map}) {
      console.log(BMap, map)
      let point = new BMap.Point(116.337766, 39.755502);
      map.centerAndZoom(point, 5); //地图缩放比例
      map.setCurrentCity("波普中心"); // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true); //鼠标滚动缩放
      this.center.lng = 116.337766
      this.center.lat = 39.755502
      this.zoom = 17
    },
    goPage(id) {
      this.$router.push({path: '/exchangeView', query: {id: id, type: 2}})
    },
    seeMore() {
      if (this.sumPage == this.nowIndexs) {
        return
      }
      this.nowIndexs++
      this.fetchData()
    }
  },
  created() {
    this.getBanner()
     this.lianxi()
  },

  filters: {
    time(dataStr) {
      if (!dataStr || dataStr == 0) {
        return '              -'
      } else {
        return moment(dataStr * 1000).format('YYYY-MM-DD HH:mm:ss')
      }
    }
  }

}
</script>

<style lang="less" scoped>
.slide1 {
  width: 100%;
  height: 31.2500vw;
  background: #e8e7e7;

  .swiper, .swiperSlide, .swiperSlide img {
    width: 100%;
    height: 100%;
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 200px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.map {
  width: 70%;
  height: 400px;
}

.team {
  height: 8vw;
  //width: 100%;
  display: block;
  margin: 0 auto;
  margin-top: 1.4792vw;
  margin-bottom: 1.4792vw;
}

/deep/ .swiper-pagination-bullet-active {
  background: #fff;
}

.team1 {

  width: 383px;
  height:77px;
  display: block;
  margin: 0 auto;
  margin-top: 1.4792vw;
  margin-bottom: 1.4792vw;
}

.content {
  width: 100%;
  min-height: calc(100vh - 10.8333vw - 4.1667vw);

  .view {
    width: 52.0833vw;
    height: auto;
    margin: 0 auto;
    padding-top: 4.1667vw;

    h1 {
      font-size: 2.0833vw;
      font-weight: bold;
      color: #333333;

    }

    .list {
      margin-top: 3.1250vw;

      .seeMore {
        text-align: center;
        font-size: 0.8333vw;
        line-height: 1.6667vw;
        margin-bottom: 2.6042vw;
        cursor: pointer;
        color: #797878;
      }

      .item1 {
        width: 40%;
        float: right;
        justify-content: flex-start;
        align-items: center;
        margin-top: 100px;

        p {
          line-height: 35px;
          font-size: 18px;
        }
      }

      .item {
        width: 60%;
        height: 30.9896vw;
        float: left;
        //display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        //border-top: 0.0521vw solid #EEEEEE;
        overflow: hidden;

        img {
          width: 12.1875vw;
          height: 6.8229vw;
        }

        > div {
          height: 6.8229vw;
          margin-right: 4.1146vw;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          overflow: hidden;
          flex: 1;
          overflow: hidden;

          h1 {
            font-size: 1.0417vw;
            font-weight: 550;
            color: #333333;

          }

          div {
            margin-top: 1.2500vw;
            margin-bottom: 1.1458vw;

            span {
              font-size: 0.6250vw;
              font-weight: 550;
              color: #999999;
              margin-right: 2vw;
            }
          }

          p {
            width: 100%;
            height: 4.8229vw;
            font-size: 0.8333vw;
            font-weight: 550;
            color: #333333;
            line-height: 2.41vw;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            /deep/ p {
              font-size: 0.8333vw !important;
              line-height: 2.41vw !important;
              color: #333333 !important;
              height: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>

